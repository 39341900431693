import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';

import { Popover, PopoverProps } from '../Popover';
import { ActionMenuGroup } from './ActionMenuGroup';
import { ActionMenuItem } from './ActionMenuItem';
import { useArrowNav } from '../../hooks/useArrowNav';
import { useLatestEvent } from './useLatestEvent';

/**
 * ActionMenu properties
 */
export type ActionMenuProps = Omit<PopoverProps, 'trigger'> & {
	trigger?:
		| PopoverProps['trigger']
		| ((params: {
				toggle: (newValue?: boolean) => void;
				isOpen: boolean;
		  }) => ReactNode);
};
export interface ActionMenuContext {
	itemsContainerRef: React.RefObject<HTMLElement>;
	onClickOutside: PopoverProps['onClickOutside'];
}

interface ActionMenu extends FC<ActionMenuProps> {
	/** Subcomponents */
	Group: typeof ActionMenuGroup;
	Item: typeof ActionMenuItem;
}

export const ActionMenu: ActionMenu = ({
	children,
	className,
	direction = 'br',
	focusTrapOptions,
	padding = null, // Disable Popover's default padding
	wrapperClassName,
	width = 's',
	open: openProp,
	trigger: triggerProp,
	onClickOutside,
	...rest
}) => {
	const menuContext = React.useContext(ActionMenuContext);
	const isNested = () => menuContext?.length && menuContext?.length > 0;

	const isControlled = typeof openProp !== 'undefined';
	const [internalOpen, setInternalOpen] = React.useState(false);
	const open = isControlled ? openProp : internalOpen;

	const handleOpenToggle = (newValue?: boolean) => {
		if (!isControlled) {
			setInternalOpen(
				typeof newValue === 'boolean' ? newValue : (prev) => !prev
			);
		}
	};

	const handleClickOutside = () => {
		if (!isControlled) {
			setInternalOpen(false);
		}
		onClickOutside?.();
	};

	const trigger =
		typeof triggerProp === 'function'
			? triggerProp({
					isOpen: open,
					toggle: handleOpenToggle,
			  })
			: triggerProp;

	const handleArrowKeys = (e: KeyboardEvent, data: any) => {
		const cascadingItem = data.active?.classList?.contains(
			'ActionMenuItem--cascading'
		);

		if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') data.disableOnce(); // Prevent focus move on left-right or when focus is not visible yet (no keyboard interaction)
		if (e.key === 'ArrowLeft' && isNested())
			rest.onClickOutside?.(e as any); // Close ActionMenu, if nested
		if (e.key === 'ArrowRight' && cascadingItem) data.active?.click?.(); // Open nested
	};

	const containerRef = useArrowNav({
		disabled: !rest.open,
		onKeyDown: handleArrowKeys,
	});

	const latestEvent = useLatestEvent();

	React.useEffect(() => {
		const el = containerRef.current;

		const setFocus = () => {
			if (!latestEvent.current) return; // Latest event is undefined yet
			if (latestEvent.current?.type === 'keydown') {
				el.querySelector('button:not([disabled])')?.focus(); // Keyboard event ? set focus first item
			} else {
				el.tabIndex = 0;
				el.focus(); // Pointer event ? set focus wrapper
				el.addEventListener('blur', () =>
					el.removeAttribute('tabIndex')
				);
			}
		};

		if (rest.open && el) Promise.resolve().then(setFocus);
	}, [rest.open]); // eslint-disable-line

	return (
		<Popover
			className={classnames(className, 'ActionMenu__OverlayWrapper')}
			focusTrapOptions={{
				autoFocus: false,
				autoFocusContainer: false,
				focusLock: false,
				restoreFocus: true,
				...focusTrapOptions,
			}}
			wrapperClassName={classnames(
				wrapperClassName,
				'ActionMenu__TriggerWrapper'
			)}
			popoverContentClassName="ActionMenu"
			direction={direction}
			padding={padding}
			portal
			width={width}
			data-anvil-component="ActionMenu"
			trigger={trigger}
			open={open}
			onClickOutside={handleClickOutside}
			{...rest}
		>
			<ActionMenuContext.Provider
				value={menuContext.concat({
					itemsContainerRef: containerRef,
					onClickOutside: rest?.onClickOutside,
				})}
			>
				<div className="ActionMenu__ItemsWrapper" ref={containerRef}>
					{children}
				</div>
			</ActionMenuContext.Provider>
		</Popover>
	);
};

ActionMenu.Group = ActionMenuGroup;
ActionMenu.Item = ActionMenuItem;

export const ActionMenuContext = React.createContext<ActionMenuContext[]>([]);
