import React, { Component, createRef } from 'react';
import classnames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { FormFieldContext } from '../FormField';

export type TextAreaElement = Pick<HTMLTextAreaElement, 'focus' | 'select'>;

/**
 * TextArea properties
 */
export interface TextAreaPropsStrict {
	/** Auto height mode on typing */
	autoHeight?: boolean;

	/** Additional classes */
	className?: string;

	/** A TextArea can show that it is disabled. */
	disabled?: boolean;

	/** Visual error state for the Textarea */
	error?: boolean;

	/** *autoHeight={true} required* Maximum rows limit for Textarea */
	maxRows?: number;

	/**
	 * Called on value change.
	 * @param {ChangeEvent} event - React's original SyntheticEvent.
	 * @param {object} data - All props and a proposed value.
	 */
	onChange?: (
		event: React.ChangeEvent<HTMLTextAreaElement>,
		data: TextAreaOnChangeData
	) => void;

	/**
	 * Called on input.
	 * @param {SyntheticEvent} event - The React SyntheticEvent object
	 * @param {object} data - All props and the event value.
	 */
	onInput?: (event: React.SyntheticEvent, data: TextAreaOnChangeData) => void;

	/** Indicates row count for a TextArea. */
	rows?: number;

	/** The value of the textarea. */
	value?: string;
}

export interface TextAreaProps extends TextAreaPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export interface TextAreaOnChangeData extends TextAreaProps {
	value: string;
}

export class TextArea extends Component<TextAreaProps> {
	private textAreaRef: React.RefObject<HTMLTextAreaElement> = createRef();
	static displayName = 'TextArea';
	static contextType = FormFieldContext;
	context!: React.ContextType<typeof FormFieldContext>;

	focus = (options?: FocusOptions) => this.textAreaRef.current.focus(options);
	select = () => this.textAreaRef.current.select();

	handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (this.props.onChange) {
			this.props.onChange(e, { ...this.props, value: e.target.value });
		}
	};

	handleInput = (e: React.SyntheticEvent) => {
		if (this.props.onInput) {
			this.props.onInput(e, {
				...this.props,
				value: (e.target as HTMLTextAreaElement).value,
			});
		}
	};

	render() {
		const {
			autoHeight,
			className,
			disabled,
			error,
			onChange,
			onInput,
			rows = 3,
			maxRows = 16,
			...rest
		} = this.props;
		const { labelFor: inputId } = this.context;

		const TextAreaClasses = classnames('TextArea', className, {
			'TextArea--disabled': disabled,
			'TextArea--error': error,
			'TextArea--auto-height': autoHeight,
		});

		const textarea = (
			<textarea
				{...rest}
				className={TextAreaClasses}
				disabled={disabled}
				onChange={this.handleChange}
				onInput={this.handleInput}
				rows={rows}
				ref={this.textAreaRef}
				id={this.props.id || inputId}
				data-anvil-component="TextArea"
			/>
		);

		const textareaAutoHeight = (
			<TextareaAutosize
				{...rest}
				className={TextAreaClasses}
				disabled={disabled}
				onChange={this.handleChange}
				onInput={this.handleInput}
				minRows={rows}
				maxRows={maxRows}
				ref={this.textAreaRef}
				cacheMeasurements
				id={this.props.id || inputId}
				data-anvil-component="TextArea"
			/>
		);

		return autoHeight ? textareaAutoHeight : textarea;
	}
}
